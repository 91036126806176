import axios from 'axios';
import { handleError } from './common';

const baseURL = `${process.env.VUE_APP_PRODUCT_MANAGER}`;
const apiBase = '/api';

axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL,
  withCredentials: true
});

async function getParcelsByClusterId(id) {
  try {
    const { data: parcels } = await client.get(`${apiBase}/cluster-parcels`, {
      params: {
        id: id
      }
    });
    if (!parcels) {
      // TODO: backend should return array instead of null
      return [];
    }
    return parcels;
  } catch (error) {
    handleError('Can not load cluster parcels', error);
  }
}
async function getParcelsByBBox(llLong, llLat, urLong, urLat, date, unitId) {
  const { data: parcels } = await client.get(`${apiBase}/parcel-by-bbox`, {
    params: {
      ll_long: llLong,
      ll_lat: llLat,
      ur_long: urLong,
      ur_lat: urLat,
      date: date,
      surveys: true,
      unit: unitId
    }
  });
  if (parcels) {
    return parcels;
  }
}
async function getFarm(farmId) {
  const { data: farm } = await client.get(`${apiBase}/farm`, {
    params: {
      id: farmId
    }
  });
  if (farm) {
    return farm;
  }

  return null;
}
async function getUnit(unitId) {
  const { data: unit } = await client.get(`${apiBase}/unit`, {
    params: {
      id: unitId
    }
  });
  return unit;
}

async function updateParcelHierarchy(parcelId, shapeId) {
  const { data: result } = await client.patch(`${apiBase}/parcel`, {
    id: parcelId,
    ShapeID: shapeId
  });
  if (result && result.success) {
    return result.success;
  }
}

async function updateParcelArea(id, area) {
  const { data: result } = await client.get(`${apiBase}/update-parcel-area`, {
    params: {
      parcel: id,
      area: area
    }
  });
  if (result && result.success) {
    return result.success;
  }
}

async function getClusterAnalytics(id) {
  const { data: analytics } = await client.get(`${apiBase}/cluster-analytics`, {
    params: {
      id: id
    }
  });
  if (analytics) {
    return analytics;
  }
}

export default {
  async getClusterParcels(id) {
    const parcels = await getParcelsByClusterId(id);
    return parcels;
  },
  /*async getParcelWithShape(id) {
    const parcel = await getParcelById(id);
    const { shape, shpid } = await getParcelShapeById(parcel.ShapeID);
    return { parcelId: parcel.id, shape: shape, shapeId: shpid };
  },*/
  async updateParcel(parcelInput, parcelId) {
    const { data: result } = await client.patch(`${apiBase}/parcel`, {
      id: parcelId,
      Shape: {
        type: 'FeatureCollection',
        features: [parcelInput.shape]
      }
    });
    if (result && result.success) {
      return result.success;
    }
  },
  async updateHierarchy(parcelId, shapeId) {
    await updateParcelHierarchy(parcelId, shapeId);
  },
  async recomputeShapes(area, parcelId) {
    await updateParcelArea(parcelId, area);
  },
  async getAnalytics(clusterId) {
    const analytics = await getClusterAnalytics(clusterId);
    return analytics;
  },
  async getParcelsByBBox(llLong, llLat, urLong, urLat, date, unitId) {
    const parcels = await getParcelsByBBox(llLong, llLat, urLong, urLat, date, unitId);
    return parcels;
  },
  async getFarm(farmId) {
    return await getFarm(farmId);
  },
  async getUnit(farmId) {
    return await getUnit(farmId);
  },
  async authorize() {
    const { data } = await client.get(`${apiBase}/authorize`, {
      withCredentials: true
    });
    return data;
  },
  async batchUpload(payload) {
    const { data } = await client.post(`${apiBase}/batch-upload`, payload, {
      withCredentials: true
    });

    if (!data.success) {
      throw Error('batchUpload failed');
    }

    return data;
  },
  async getOrganizations(countryId) {
    const { data } = await client.get(`${apiBase}/organizations?country=${countryId}`, {
      withCredentials: true
    });
    return data;
  },
  async getUnits(orgId) {
    const { data } = await client.get(`${apiBase}/units?org=${orgId}`, {
      withCredentials: true
    });
    return data;
  },
  async getFarms(unitId) {
    try {
      const { data: farms } = await client.get(`${apiBase}/farms?unit=${unitId}`, {
        withCredentials: true
      });
      if (!farms) {
        // TODO: backend should return array instead of null
        return [];
      }
      return farms;
    } catch (error) {
      handleError('Can not load farms', error);
    }
  },
  async refreshSession() {
    const { data } = await client.get(`${apiBase}/refresh-session`, {
      withCredentials: true
    });
    return data;
  },
  async getParcelById(parcelId) {
    const { data } = await client.get(`${apiBase}/parcel?id=${parcelId}`, {
      withCredentials: true
    });
    return data;
  },
  async getOrganizationIdByParcelId(parcelId) {
    const parcelData = await this.getParcelById(parcelId);
    if (!parcelData) {
      return null;
    }

    const farmData = await this.getFarm(parcelData.FarmID);
    if (!farmData) {
      return null;
    }

    const unitData = await this.getUnit(farmData.UnitID);
    if (!unitData) {
      return null;
    }

    return unitData.OrganizationID;
  }
};
