import { message } from 'ant-design-vue';

export function handleError(clientError = 'something went wrong', serviceError) {
  let serviceErrorMsg = '';
  if (serviceError.response && serviceError.response.data && serviceError.response.data.message) {
    serviceErrorMsg = `${serviceError.response.data.message}, statusCode: ${serviceError.response.status}`;
  } else if (serviceError.response) {
    serviceErrorMsg = `${serviceError.message}, statusCode: ${serviceError.response.status}`;
  } else {
    serviceErrorMsg = serviceError.message;
  }

  message.error((createElement) => {
    return createElement('span', [
      createElement('span', clientError),
      createElement('br'),
      createElement('span', serviceErrorMsg)
    ]);
  }, 5);

  throw serviceError;
}
