<template>
  <div>
    <div v-if="isChanging" class="loader">
      <a-spin tip="Loading..." />
    </div>
    <div class="map-container">
      <div class="map-overlay">
        <div v-if="!processing">
          <div style="float: left; width: 100%">
            <a-button
              v-if="currentStep == 'Editing'"
              type="primary"
              :disabled="!isDirty || !shapesValid"
              @click="handleSave()"
              >Save shapes</a-button
            >
            <a-button
              v-if="currentStep === 'Editing'"
              type="primary"
              :disabled="!isDirty || !shapesValid"
              style="margin-top: 10px"
              @click="handleSaveAndContinueToCarving()"
              >Finish Shape Editing</a-button
            >
            <a-button v-if="currentStep == 'Carving'" class="btn-indent" :disabled="finalizing" @click="handleCarving()"
              >Carve Parcel Shapes</a-button
            >

            <a-button
              v-if="currentStep == 'Process'"
              type="primary"
              :disabled="selectedParcelsSize == 0 || selectedAnalytics.length == 0"
              @click="handleProcess()"
              >Start Processing</a-button
            >
          </div>
          <div v-if="currentStep == 'Process'">
            <h3>Analytics</h3>
            <a-checkbox-group v-model="selectedAnalytics" :options="analytics">
              <span slot="label" slot-scope="{ value }">
                <span>{{ value }}</span>
                <a-radio-group
                  v-if="value === 'sowing'"
                  v-model="selectedPlantingLines"
                  :options="plantingLines"
                  :disabled="isSowingOptionsDisabled"
                ></a-radio-group>
                <a-radio-group
                  v-if="value == 'weeds'"
                  v-model="selectedWeedsModel"
                  :options="models"
                  :disabled="isWeedsOptionsDisabled"
                ></a-radio-group>
              </span>
            </a-checkbox-group>
          </div>
          <div v-if="currentStep == 'Process'">
            <h3>Selected Parcels</h3>
            <span>{{ selectedParcelsSize }}</span>
          </div>
          <div style="float: left; margin-top: 20px">
            <a-button type="primary" :disabled="isDirty || disableBack" @click="prevStep()">
              <a-icon type="arrow-left" />Back
            </a-button>
          </div>
          <div style="float: right; margin-top: 20px">
            <a-button
              v-if="currentStep != 'Process'"
              type="primary"
              :disabled="isDirty || disableNext || !shapesValid"
              @click="nextStep()"
            >
              Next
              <a-icon type="arrow-right" />
            </a-button>

            <a-button
              v-if="currentStep === 'Process'"
              type="primary"
              :disabled="selectedParcelsSize === 0"
              @click="unselectAllParcels()"
              >Unselect All</a-button
            >
          </div>
        </div>
        <div v-if="processing">
          <div>{{ state }}</div>
          <a-progress :percent="progress" />
        </div>
      </div>
      <div v-if="!!currentMapMode" class="map-mode">
        <div class="map-mode-label">{{ currentMapMode }}</div>
      </div>
      <div class="map-overlay buttons">
        <div
          class="btn delete"
          :class="{ active: selectedDeleteModeType === 'default' }"
          @click="deletePainted('default')"
        >
          Draw free area hole
        </div>
        <div
          class="btn delete"
          :class="{ active: selectedDeleteModeType === 'medium' }"
          @click="deletePainted('medium')"
        >
          Draw 0.8m line hole
        </div>
        <div class="btn delete" :class="{ active: selectedDeleteModeType === 'high' }" @click="deletePainted('high')">
          Draw 1m line hole
        </div>

        <div v-if="!!drawSelectedFeatureIds" class="btn simplify" @click="makeSimple">Simplify</div>
        <div
          v-if="!!drawSelectedFeatureIds && drawSelectedFeatureIds.length === 1"
          class="btn replace"
          @click="replace"
        >
          Replace (Shift+R)
        </div>
        <div v-if="drawHistory.length > 1" class="btn undo" @click="applyFromHistory">Undo (Shift+Z)</div>

        <div class="mode-picker-description">*press Shift when painting</div>
      </div>
      <mapbox
        access-token="pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ"
        :map-options="{
          style: 'mapbox://styles/mapbox/satellite-v9',
          center: [-96, 37.8],
          zoom: 3,
          tileSize: 256,
          preserveDrawingBuffer: true,
          dragRotate: false,
          touchZoomRotate: false
        }"
        @map-load="loaded"
        @map-init="initalized"
      />
    </div>
    <ShapeEditingModal
      v-if="showShapeEditingForId !== null"
      :survey-info="surveyInfo"
      :feature-id="showShapeEditingForId"
      :organization="organization"
      :unit="unit"
      :farm="farm"
      @onEdited="onShapeEdited"
    />
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import { featureEach } from '@turf/meta';
import axios from 'axios';
import moment from 'moment';
import turfBbox from '@turf/bbox';
import turfBuffer from '@turf/buffer';
import turfBBoxPolygon from '@turf/bbox-polygon';
import turfArea from '@turf/area';
import turfIntersect from '@turf/intersect';
import turfKinks from '@turf/kinks';
import productManagerProvider from '../providers/product-manager-api';
import { message } from 'ant-design-vue';
import { featureCollection, polygon } from '@turf/helpers';
import simplify from '@turf/simplify';
import cleanCoords from '@turf/clean-coords';
import paintMode from './paint-mode';
import intersect from '@turf/intersect';
import booleanContains from '@turf/boolean-contains';
import ShapeEditingModal from '@/components/ShapeEditingModal';
import apiProvider from '../providers/api';

export default {
  components: { Mapbox, ShapeEditingModal },
  props: {
    bbox: {
      type: Array,
      default: () => []
    },
    orthoName: {
      type: String,
      default: ''
    },
    surveyInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      map: null,
      draw: null,
      isChanging: false,
      isDirty: false,
      finalizing: false,
      disableBack: false,
      disableNext: false,
      shapesValid: true,
      currentStep: 'Editing',
      state: '',
      selectedParcels: [],
      analytics: [],
      plantingLines: [],
      selectedPlantingLines: 'Single',
      selectedAnalytics: [],
      overlaps: {},
      progress: 0,
      processing: false,
      updatedParcelIds: new Set(),
      createdParcelIds: [],
      createdParcelsData: {},
      drawHistory: [],
      drawSelectedFeatureIds: null,
      replaceModeFeature: false,
      isDeleteMode: false,
      holeBufferSize: null,
      selectedDeleteModeType: null,
      readOnlyShapes: [],
      showShapeEditingForId: null,
      organization: null,
      unit: null,
      farm: null,
      selectedWeedsModel: '0',
      models: [
        { value: '0', label: 'Default' },
        { value: '1', label: 'Second' }
      ]
    };
  },
  computed: {
    selectedParcelsSize() {
      return this.selectedParcels.length;
    },
    currentMapMode() {
      if (this.selectedDeleteModeType) {
        if (this.selectedDeleteModeType === 'default') {
          return 'Free area hole mode';
        }
        if (this.selectedDeleteModeType === 'medium') {
          return '0.8m line hole mode';
        }
        if (this.selectedDeleteModeType === 'high') {
          return '1m line hole mode';
        }
      }
      return null;
    },
    isSowingOptionsDisabled() {
      return !this.selectedAnalytics.includes('sowing');
    },
    isWeedsOptionsDisabled() {
      return !this.selectedAnalytics.includes('weeds');
    }
  },
  mounted() {},
  destroyed() {
    document.onkeyup = null;
  },
  methods: {
    async loaded(map) {
      this.map = map;
      await this.$nextTick();
      // wait to set object to data
      //fix issue with styling draw layers

      await this.loadMapData();

      document.onkeyup = (e) => {
        if (e.shiftKey) {
          switch (e.code) {
            case 'KeyZ':
              this.applyFromHistory();
              break;
            case 'KeyR':
              this.replace();
              break;
          }
        }
      };
    },
    initalized() {},
    getCountryId() {
      // return Brazil country
      if (process.env.VUE_APP_ENV === 'dev') {
        return '5e4e9c5a72310000e6004ae1';
      }
      return '5e7ebac48e117b6f00f5f4be';
    },
    async loadHierarchyForNewParcels() {
      const countryId = this.getCountryId();
      const organizations = await productManagerProvider.getOrganizations(countryId);
      if (this.surveyInfo.organizationId) {
        this.organization = organizations.find((org) => org.id === this.surveyInfo.organizationId);
      } else if (this.surveyInfo.organizationName) {
        this.organization = organizations.find((org) => org.Name === this.surveyInfo.organizationName);
      } else if (this.surveyInfo.parcels && this.surveyInfo.parcels.length) {
        const firstParcelId = this.surveyInfo.parcels[0];
        const organizationId = await productManagerProvider.getOrganizationIdByParcelId(firstParcelId);
        if (organizationId) {
          this.organization = organizations.find((org) => org.id === organizationId);
        }
      }

      if (!this.organization) {
        this.organization = organizations[0];
      }

      const units = await productManagerProvider.getUnits(this.organization.id);
      this.unit = units[0];
      const farms = await productManagerProvider.getFarms(this.unit.id);
      const baseDemoFarmName = 'DemoFarm';
      const demoFarmNames = farms.map((farm) => farm.Name).filter((name) => name.indexOf(baseDemoFarmName) === 0);
      const demoFarmIndexes = demoFarmNames
        .map((name) => name.substring(baseDemoFarmName.length))
        .filter((pos) => !!pos)
        .map((pos) => parseInt(pos));
      let farmName = baseDemoFarmName;
      if (demoFarmIndexes.length) {
        const index = Math.max.apply(null, demoFarmIndexes);
        farmName = `${baseDemoFarmName}${index + 1}`;
      } else if (demoFarmNames.length) {
        farmName = `${baseDemoFarmName}1`;
      }
      this.farm = {
        Name: farmName
      };
    },
    hasParcelsSurveys(parcels) {
      return parcels.some((p) => !!p.SurveyID && p.SurveyID.length > 0);
    },
    async loadMapData() {
      const { data: orthoInfo } = await this.getOrtoBounds(this.orthoName);
      const orthoCoordinates = this.getOrtoCoordinates(orthoInfo);

      this.analytics = await productManagerProvider.getAnalytics(this.orthoName);
      if (this.analytics.some((x) => x === 'sowing')) {
        this.plantingLines = ['Single', 'Double'];
      }
      this.selectedAnalytics = [...this.analytics];
      if (this.surveyInfo.analytics && this.surveyInfo.analytics.length) {
        const selectedAnalytics = this.surveyInfo.analytics
          .map((analyticOption) => analyticOption.analyticType)
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        this.selectedAnalytics = [...selectedAnalytics];
        this.analytics = this.analytics.map((analytic) => {
          return { value: analytic, disabled: false };
        });
        const selectedSowingOption = this.surveyInfo.analytics.find(
          (analyticOption) => analyticOption.analyticType === 'sowing' && !!analyticOption.type
        );
        if (selectedSowingOption) {
          this.selectedPlantingLines = selectedSowingOption.type;
        }
      }
      await this.loadHierarchyForNewParcels();

      const parcels = await productManagerProvider.getClusterParcels(this.orthoName);
      let allParcels = [];

      if (parcels.length) {
        const farmId = parcels[0].FarmID;
        const farm = await productManagerProvider.getFarm(farmId);
        const unit = await productManagerProvider.getUnit(farm.UnitID);
        this.overlaps = this.surveyInfo.overlaps;
        const bbox = turfBbox(turfBuffer(turfBBoxPolygon(this.getBbox(parcels)), 1)); //buffer by 1 km

        allParcels = await productManagerProvider.getParcelsByBBox(
          bbox[0],
          bbox[1],
          bbox[2],
          bbox[3],
          moment.utc(this.surveyInfo.surveyDate).format('YYYY-MM-DD'),
          unit.id
        );
      } else {
        // no parcels flow (web-uploader)
        const orthoBbox = this.getOrtoBbox(orthoInfo);
        const bboxPolygon = turfBBoxPolygon(orthoBbox);
        const bufferedBboxPolygon = turfBuffer(bboxPolygon, 1); //buffer by 1 km
        const bufferedOrthoBbox = turfBbox(bufferedBboxPolygon);
        this.map.fitBounds(bufferedOrthoBbox, { animate: false });

        allParcels = await productManagerProvider.getParcelsByBBox(
          bufferedOrthoBbox[0],
          bufferedOrthoBbox[1],
          bufferedOrthoBbox[2],
          bufferedOrthoBbox[3],
          moment.utc(this.surveyInfo.surveyDate).format('YYYY-MM-DD'),
          this.unit.id
        );
      }

      if (this.hasParcelsSurveys(parcels)) {
        const surveyIds = parcels.map((parcel) => ({ id: parcel.SurveyID, date: parcel.LastUpdate }));
        this.showCarvedShapes(surveyIds);
        return;
      } else {
        this.drawOrtho(orthoCoordinates, this.orthoName);
      }

      paintMode.onDrawFinished = (polygon) => {
        if (polygon) {
          this.deleteFeatures(polygon);
        }
      };

      this.draw = new MapboxDraw({
        modes: {
          paint: paintMode,
          ...MapboxDraw.modes
        },
        controls: {
          polygon: true,
          trash: true
        },
        displayControlsDefault: false
      });

      this.map.addControl(this.draw, 'top-right');

      this.map.on('draw.modechange', () => {
        if (this.replaceModeFeature) {
          this.revertReplaceModeFeature();
        }
        this.isDeleteMode = false;
        this.selectedDeleteModeType = null;
        this.map.getCanvas().style.cursor = 'unset';
      });

      this.map.on('draw.selectionchange', (e) => {
        const newSelectedFeaturesIds = e && e.features && e.features.length ? e.features.map((f) => f.id) : [];
        this.drawSelectedFeatureIds = newSelectedFeaturesIds.length ? newSelectedFeaturesIds : null;
      });

      this.map.on('draw.create', (e) => {
        if (e.features && e.features.length) {
          if (this.replaceModeFeature) {
            Object.keys(this.replaceModeFeature.properties).forEach((key) => {
              this.draw.setFeatureProperty(e.features[0].id, key, this.replaceModeFeature.properties[key]);
            });
            this.updatedParcelIds.add(this.replaceModeFeature.properties.id);
            this.replaceModeFeature = null;
          } else {
            this.createdParcelIds.push(e.features[0].id);
            this.showShapeEditingForId = e.features[0].id;
          }
          if (!this.isDeleteMode) {
            this.saveToHistory();
          }
          this.isDirty = true;
        }
      });

      this.map.on('draw.delete', () => {
        if (!this.isDeleteMode) {
          this.saveToHistory();
        }
        this.isDirty = true;
        this.drawSelectedFeatureIds = null;
      });

      this.map.on('draw.update', (ev) => {
        const { features } = ev;
        this.shapesValid = true;
        const allFeatures = this.draw.getAll().features;
        this.checkOverlap([...allFeatures, ...this.readOnlyShapes]);

        features.forEach((feature) => {
          if (!this.updatedParcelIds.has(feature.properties.id)) {
            this.updatedParcelIds.add(feature.properties.id); // set only unique id
          }
        });
        this.isDirty = true;
        if (!this.isDeleteMode) {
          this.saveToHistory();
        }
      });

      this.drawParcelShapes(allParcels);
    },

    deleteFeatures(cutPolygon) {
      const polygons = this.draw.getAll();
      const featuresToDelete = [];
      let featuresToAdd = [];
      polygons.features.forEach((feature) => {
        const intersection = intersect(cutPolygon, feature);
        if (intersection) {
          this.updatedParcelIds.add(feature.properties.id);
          featuresToDelete.push(feature.id);
          const fullyContains = booleanContains(feature, cutPolygon);
          const pCoordinates = fullyContains ? cutPolygon.geometry.coordinates : intersection.geometry.coordinates;
          const rings = [...feature.geometry.coordinates, ...pCoordinates];
          const polygonWithRings = polygon(rings, feature.properties);
          featuresToAdd = featuresToAdd.concat(polygonWithRings);
        }
      });
      if (featuresToDelete.length) {
        this.draw.delete(featuresToDelete);
      }
      if (featuresToAdd.length) {
        this.draw.add(featureCollection(featuresToAdd));
      }
      if (featuresToDelete.length || featuresToAdd.length) {
        this.saveToHistory();
      }
    },

    onShapeEdited(featureId, data) {
      this.createdParcelsData[featureId] = data;
      this.showShapeEditingForId = null;
    },

    getBbox(parcels) {
      let allShapes = { type: 'FeatureCollection', features: [] };
      parcels.map((parcelData) => {
        allShapes.features.push(parcelData.Shape.features[0]);
      });
      return turfBbox(allShapes);
    },
    drawParcelShapes(parcels) {
      if (parcels && parcels.length) {
        const shapesBbox = this.getBbox(parcels);
        this.readOnlyShapes = [];
        let allShapes = { type: 'FeatureCollection', features: [] };
        parcels.map((parcelData) => {
          const shapeCollection = { ...parcelData.Shape };
          shapeCollection.features.map((f) => {
            f.properties.id = parcelData.id; //TODO: We have to handle differently when we have mulitple features in parcel.
            f.properties.shp = parcelData.Shape.id;
          });
          allShapes.features.push(parcelData.Shape.features[0]);
          if (parcelData.Surveys == null) {
            this.draw.add(shapeCollection);
          } else {
            this.readOnlyShapes.push(parcelData.Shape.features[0]);
            this.map.addLayer({
              id: parcelData.id,
              type: 'line',
              source: {
                type: 'geojson',
                data: shapeCollection
              },
              paint: {
                'line-color': 'blue',
                'line-width': 2,
                'line-dasharray': [2, 1]
              }
            });
          }
        });
        this.map.fitBounds(shapesBbox, { animate: false });
        this.checkOverlap(allShapes.features);
        this.saveToHistory();
      }
    },

    simplifyPolygon(polygon) {
      const cv = cleanCoords(polygon);
      return simplify(cv, { tolerance: 0.000001 });
    },

    deletePainted(modeType) {
      const bufferSizeToType = {
        default: null,
        medium: 0.8,
        high: 1
      };
      const modeTypeKey = modeType || 'default';
      const buffer = bufferSizeToType[modeTypeKey];
      this.selectedDeleteModeType = modeTypeKey;

      if (this.isDeleteMode && this.holeBufferSize === buffer) {
        this.draw.changeMode('simple_select');
        this.isDeleteMode = false;
        this.selectedDeleteModeType = null;
        this.holeBufferSize = null;
        this.map.getCanvas().style.cursor = 'unset';
        return;
      }

      this.isDeleteMode = true;
      this.holeBufferSize = buffer;
      this.map.getCanvas().style.cursor = 'crosshair';
      this.draw.changeMode('paint', buffer ? { lineBuffer: buffer } : {});
    },

    makeSimple() {
      const features = this.draw.getSelected();
      const featuresIds = [];
      const simplified = [];
      featureEach(features, (feature) => {
        featuresIds.push(feature.id);
        simplified.push(this.simplifyPolygon(feature));
      });
      if (featuresIds.length) {
        this.draw.delete(featuresIds);
        const addedFeatureIds = this.draw.add(featureCollection(simplified));
        this.draw.changeMode('simple_select', {
          featureIds: addedFeatureIds
        });
        this.saveToHistory();
      }
    },

    revertReplaceModeFeature() {
      if (this.replaceModeFeature) {
        this.draw.add(featureCollection([this.replaceModeFeature]));
        this.replaceModeFeature = null;
      }
    },

    replace() {
      const collection = this.draw.getSelected();
      if (collection && collection.features && collection.features.length) {
        this.replaceModeFeature = collection.features[0];
        this.draw.delete(collection.features[0].id);
        this.draw.changeMode('draw_polygon');
        this.drawSelectedFeatureIds = null;
      }
    },

    saveToHistory() {
      const polygons = this.draw.getAll();
      if (this.drawHistory.length === 5) {
        this.drawHistory = this.drawHistory.slice(1);
      }
      this.drawHistory.push(polygons);
    },

    applyFromHistory() {
      if (this.drawHistory.length > 1) {
        const index = this.drawHistory.length - 2;
        const polygons = this.drawHistory[index];
        this.draw.deleteAll();
        this.draw.add(polygons);
        this.drawHistory = this.drawHistory.slice(0, index + 1);
        this.checkOverlap(polygons);
        this.drawSelectedFeatureIds = null;
      }
    },

    checkOverlap(allShapes) {
      if (this.map.getLayer('overlaps') != null) {
        this.map.removeLayer('overlaps');
        this.map.removeSource('overlaps');
      }
      for (let i = 0; i < allShapes.length; i++) {
        for (let j = i + 1; j < allShapes.length; j++) {
          const overlap = turfIntersect(allShapes[i], allShapes[j]);
          if (overlap != null) {
            const buffered = turfBuffer(overlap, 5, { units: 'meters' });
            this.shapesValid = false;
            message.error('Parcels should not overlap.');
            this.map.addLayer({
              id: 'overlaps',
              type: 'fill',
              source: {
                type: 'geojson',
                data: buffered
              },
              paint: { 'fill-color': 'rgba(255, 0, 0, 0.5)', 'fill-outline-color': 'rgb(255, 0, 0)' }
            });
            const bbox = turfBbox(overlap);
            this.map.fitBounds(bbox, { animate: false });
            return;
          }
        }
        const kinks = turfKinks(allShapes[i]);
        if (kinks) {
          const kinkFeatures = [];
          kinks.features.forEach((kink) => {
            const buffered = turfBuffer(kink, 2, { units: 'meters' });
            kinkFeatures.push(buffered);
          });
          if (kinkFeatures.length > 0) {
            this.shapesValid = false;
            const fcKinks = featureCollection(kinkFeatures);
            this.map.addLayer({
              id: 'overlaps',
              type: 'fill',
              source: {
                type: 'geojson',
                data: fcKinks
              },
              paint: { 'fill-color': 'rgba(255, 0, 0, 0.5)', 'fill-outline-color': 'rgb(255, 0, 0)' }
            });
            const box = turfBbox(fcKinks);
            this.map.fitBounds(box, { animate: false });
            return;
          }
        }
      }
    },
    drawOrtho(bounds, name) {
      this.map.addSource('ortho', {
        type: 'raster',
        tiles: [
          `https://storage.googleapis.com/drone-tiles-${
            process.env.VUE_APP_ENV
          }/${name}/{z}/{x}/{y}.png?v=${new Date().getTime()}`
        ],
        tileSize: 256
      });
      this.map.addLayer({
        id: 'ortho-layer',
        type: 'raster',
        source: 'ortho',
        paint: {}
      });
    },
    async showCarvedShapes(surveIds) {
      if (this.map.getLayer('ortho-layer') != null) this.map.removeLayer('ortho-layer');
      if (this.map.getSource('ortho') != null) {
        this.map.removeSource('ortho');
      }
      if (this.draw != null) {
        this.draw.deleteAll();
        this.saveToHistory();
      }
      const parcels = await productManagerProvider.getClusterParcels(this.orthoName);
      let allShapes = { type: 'FeatureCollection', features: [] };
      this.featureIdMap = {};
      let idx = 0;
      this.parcelsForSelection = parcels;
      parcels.map((parcelData) => {
        let feat = parcelData.Shape.features[0];
        feat.id = idx;
        this.featureIdMap[parcelData.id] = idx;
        idx++;
        feat.properties.id = parcelData.id;
        allShapes.features.push(feat);
      });

      this.map.on('sourcedata', this.checkTilesLoaded);

      surveIds.map((survey) => {
        const parcel = parcels.filter((x) => x.SurveyID == survey.id)[0];
        const bbox = turfBbox(parcel.Shape);
        this.map.addSource(`ortho-${survey.id}`, {
          type: 'raster',
          tiles: [
            `https://storage.googleapis.com/drone-tiles-${process.env.VUE_APP_ENV}/${survey.id}/{z}/{x}/{y}.png?v=${survey.date}`
          ],
          bounds: bbox,
          tileSize: 256
        });
        this.map.addLayer({
          id: `ortho-layer-${survey.id}`,
          type: 'raster',
          source: `ortho-${survey.id}`,
          paint: {}
        });
      });

      const shapesBbox = turfBbox(allShapes);
      this.map.fitBounds(shapesBbox, { animate: false });
      this.map.addSource('parcel-shapes', {
        type: 'geojson',
        /*generateId: true,*/
        data: allShapes
      });

      this.map.addLayer({
        id: 'parcel-selection-layer',
        type: 'fill',
        source: 'parcel-shapes',
        paint: {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            'rgba(255, 0, 0, 0.5)',
            'rgba(0, 0, 0, 0.5)'
          ],
          'fill-outline-color': 'rgb(120, 80, 245)'
        }
      });
      this.map.addLayer({
        id: 'parcel-shapes-layer',
        type: 'line',
        source: 'parcel-shapes',
        paint: {
          'line-color': '#ffff00',
          'line-width': 2
        }
      });
      /*if (this.overlaps != null) {
        for (const parcelID in this.overlaps) {
          this.map.setFeatureState(
            { source: 'parcel-shapes', id: featureIdMap[parcelID] },
            { ['selected']: this.overlaps[parcelID] >= 100 }
          );
        }
      }*/
      //this.isDirty = true;
      this.currentStep = 'Process';

      allShapes.features.map((f) => {
        this.map.setFeatureState({ source: 'parcel-shapes', id: f.properties.id }, { ['selected']: false });
      });
      this.map.on('click', 'parcel-selection-layer', (ev) => {
        this.map.off('sourcedata', this.checkTilesLoaded);
        const state = this.map.getFeatureState({ source: 'parcel-shapes', id: ev.features[0].id });
        let selected = false;

        const parcelID = ev.features[0].properties.id;
        if (!this.areTilesLoaded('ortho-' + this.parcelsForSelection.filter((p) => p.id == parcelID)[0].SurveyID)) {
          message.error('Selected parcel has no tiles.');
          return;
        }
        if (state.selected != null) selected = state.selected;
        if (selected) {
          if (this.selectedParcels.indexOf(parcelID) >= 0)
            this.selectedParcels.splice(this.selectedParcels.indexOf(parcelID), 1);
        } else {
          if (this.selectedParcels.indexOf(parcelID) < 0) this.selectedParcels.push(parcelID);
        }
        this.map.setFeatureState({ source: 'parcel-shapes', id: ev.features[0].id }, { ['selected']: !selected });
      });
    },
    getOrtoCoordinates(orthoInfo) {
      return [
        [orthoInfo.minx, orthoInfo.maxy],
        [orthoInfo.maxx, orthoInfo.maxy],
        [orthoInfo.maxx, orthoInfo.miny],
        [orthoInfo.minx, orthoInfo.miny]
      ];
    },
    getOrtoBbox(orthoInfo) {
      return [orthoInfo.minx, orthoInfo.maxy, orthoInfo.maxx, orthoInfo.maxy];
    },
    checkTilesLoaded(e) {
      if (e.sourceId != 'mapbox') {
        this.parcelsForSelection.map((p) => {
          if (
            this.selectedParcels.indexOf(p.id) < 0 &&
            (this.overlaps == null || this.overlaps[p.id] >= 100) &&
            this.areTilesLoaded('ortho-' + p.SurveyID)
          ) {
            this.map.setFeatureState({ source: 'parcel-shapes', id: this.featureIdMap[p.id] }, { ['selected']: true });
            this.selectedParcels.push(p.id);
          }
        });
      }
    },
    areTilesLoaded(sourceId) {
      var sourceCache = this.map.style && this.map.style._sourceCaches[`other:${sourceId}`];
      var tiles = sourceCache._tiles;
      for (var tileId in tiles) {
        var r = tiles[tileId];
        if ('loaded' === r.state) return true;
      }
      return false;
    },
    async getOrtoBounds(name) {
      return axios({
        method: 'GET',
        url: process.env.VUE_APP_APPSERVER + '/getBounds?name=' + name
      });
    },

    getUpdatedFeatures() {
      const updatedFeatures = [];
      featureEach(this.draw.getAll(), (feature) => {
        if (this.updatedParcelIds.has(feature.properties.id)) {
          updatedFeatures.push(feature);
        }
      });
      return updatedFeatures;
    },
    async prevStep() {
      switch (this.currentStep) {
        case 'Editing':
          this.$emit('back');
          break;
        case 'Carving':
          this.currentStep = 'Editing';
          this.disableNext = false;
          break;
        case 'Process':
          if (confirm('Are you sure you want to restart from alignment step?')) {
            axios
              .get(process.env.VUE_APP_APPSERVER + '/clear-cluster?id=' + this.orthoName, { withCredentials: true })
              .then(() => {
                window.location.reload();
              });
            //this.currentStep = 'Carving';
          }
      }
    },
    nextStep() {
      switch (this.currentStep) {
        case 'Editing':
          this.currentStep = 'Carving';
          this.disableNext = true;
          break;
        case 'Carving':
          this.currentStep = 'Process';
          break;
      }
    },
    async saveUpdatedFeatures() {
      const updatedFeatures = this.getUpdatedFeatures();
      const updateParcelShapesPromises = updatedFeatures.map((feature) => {
        return productManagerProvider.updateParcel({ shape: feature }, feature.properties.id);
      });

      try {
        await Promise.all(updateParcelShapesPromises);
      } catch (e) {
        message.error('Error occurred during updating shapes', 5);
        return;
      }

      const parcelHierarchyPromises = updatedFeatures.map((feature) => {
        return productManagerProvider.updateHierarchy(feature.properties.id, feature.properties.shp);
      });

      await Promise.all(parcelHierarchyPromises).catch(() => {
        message.error('Error occurred while updating parcel hierarchy shapes', 5);
      });

      const recalcParcelShapesPromises = updatedFeatures.map((feature) => {
        let area = turfArea(feature);
        return productManagerProvider.recomputeShapes(area, feature.properties.id);
      });

      await Promise.all(recalcParcelShapesPromises).catch(() => {
        message.error('Error occurred during recalculating shapes', 5);
      });
      this.updatedParcelIds.clear();
    },
    async saveCreatedFeatures() {
      const parcels = [];
      featureEach(this.draw.getAll(), (feature) => {
        if (this.createdParcelIds.includes(feature.id)) {
          const data = this.createdParcelsData[feature.id];
          if (data) {
            parcels.push({
              Name: data.parcelName,
              Created: data.plantingDate,
              Shape: featureCollection([feature])
            });
          }
        }
      });

      if (parcels.length) {
        const request = {
          CountryID: this.unit.CountryID,
          CropID: this.unit.CropID,
          OrganizationID: this.unit.OrganizationID,
          Units: [
            {
              id: this.unit.id,
              Farms: [
                {
                  Name: this.farm.Name,
                  Parcels: parcels
                }
              ]
            }
          ]
        };

        try {
          const data = await productManagerProvider.batchUpload(request);
          this.createdParcelIds = [];
          return data.createdParcelIds;
        } catch (e) {
          message.error('Error occurred during creating shapes', 5);
        }
      }
    },
    async handleSave() {
      this.isChanging = true;

      await this.saveUpdatedFeatures();

      const createdParcelIds = await this.saveCreatedFeatures();
      if (createdParcelIds && createdParcelIds.length) {
        await apiProvider.updateClusterMeta(this.orthoName, { parcelIds: createdParcelIds });
      }

      this.isChanging = false;
      this.isDirty = false;
      this.currentStep = 'Editing';
      this.disableNext = false;
    },
    async handleSaveAndContinueToCarving() {
      this.isChanging = true;

      await this.saveUpdatedFeatures();

      const createdParcelIds = await this.saveCreatedFeatures();
      if (createdParcelIds && createdParcelIds.length) {
        await apiProvider.updateClusterMeta(this.orthoName, { parcelIds: createdParcelIds });
      }

      this.isChanging = false;
      this.isDirty = false;
      this.currentStep = 'Carving';
      this.disableNext = true;
    },
    async handleCarving() {
      this.finalizing = true;
      this.processing = true;
      await axios
        .get(process.env.VUE_APP_APPSERVER + '/finalize?id=' + this.orthoName, { withCredentials: true })
        .then((result) => {
          /*if (result.data.success) {
            this.finalizing = false;
            message.success('Finished carving.');
            this.currentStep='Process';
          } else {
            message.error('Error while carving.');
          }*/
          if (result.data) {
            setTimeout(() => {
              this.getProgress(true);
            }, 3000);
          }
        });
    },
    getProgress() {
      axios
        .get(process.env.VUE_APP_APPSERVER + '/getProgress?id=' + this.orthoName, { withCredentials: true })
        .then((res) => {
          if (res.data.state != null) {
            this.state = res.data.state;
            if (this.state == 'Error') {
              message.error(res.data.message, 0);
              return;
            }
            if (this.state == 'Completed') {
              this.isDirty = false;
              this.processing = false;
              this.disableNext = false;
              productManagerProvider.getClusterParcels(this.orthoName).then((parcels) => {
                let surveyIds = [];
                parcels.map((x) => {
                  surveyIds.push({ id: x.SurveyID, date: x.LastUpdate });
                });
                this.showCarvedShapes(surveyIds);
              });
            } else {
              if (res.data.progress > 100) {
                res.data.progress = 100;
              }
              switch (this.state) {
                case 'Copying':
                  this.progress = res.data.progress * 0.1;
                  break;
                case 'Total Carving':
                  this.progress = 9 + res.data.progress * 0.9;
                  break;
              }
              this.progress = Math.round(this.progress);
              setTimeout(() => {
                this.getProgress();
              }, 5000);
            }
          } else {
            setTimeout(() => {
              this.getProgress();
            }, 5000);
          }
        });
    },
    handleProcess() {
      let area = 0;
      featureEach(this.map.getSource('parcel-shapes')._options.data, (feature) => {
        const state = this.map.getFeatureState({ source: 'parcel-shapes', id: feature.id });
        if (state.selected != false) {
          area += turfArea(feature);
        }
      });

      axios
        .get(
          process.env.VUE_APP_APPSERVER +
            '/process?cluster=' +
            this.orthoName +
            '&parcels=' +
            Array.from(this.selectedParcels).join(',') +
            '&analytics=' +
            Array.from(this.selectedAnalytics).join(',') +
            '&area=' +
            Math.round(area) +
            '&plantingLines=' +
            this.selectedPlantingLines +
            '&model=' +
            this.selectedWeedsModel
        )
        .then((res) => {
          if (res.data.success) {
            message.info('Successfully submitted for processing.');
          } else {
            message.error('Error occurred while submitting for processing.');
          }
        });
    },
    unselectAllParcels() {
      const parcelShapesSource = this.map.getSource('parcel-shapes');
      if (parcelShapesSource) {
        featureEach(parcelShapesSource._options.data, (feature) => {
          this.map.setFeatureState({ source: 'parcel-shapes', id: feature.id }, { selected: false });
        });

        this.selectedParcels = [];
      }
    }
  }
};
</script>

<style scoped>
h3 {
  margin-bottom: 0;
  font-weight: bold;
  margin-top: 10px;
  float: left;
  width: 100%;
}
#map {
  height: 100%;
  width: 100%;
}
.map-container {
  position: relative;
  height: calc(100vh - 0px);
  width: 100%;
}
.map-overlay {
  z-index: 2;
  position: absolute;
  left: 10px;
  top: 15px;
  background: rgba(221, 221, 221, 0.7);
  padding: 20px;
  width: 290px;
  border-radius: 5px;
}

.map-overlay.buttons {
  margin-left: 305px;
  width: auto;
}

.btn {
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  color: #1f1f1f;
}

.btn.simplify {
  background-color: #dddddd;
  margin-top: 10px;
}

.btn.replace {
  background-color: #ffe343;
  margin-top: 10px;
}

.btn.undo {
  background-color: #4d89fc;
  margin-top: 10px;
}

.btn.delete {
  background-color: black;
  color: white;
  margin-top: 10px;
}

.btn.delete.active {
  border: 1px solid #fff;
  background-color: #1890ff;
}

.mode-picker-description {
  font-size: 12px;
  margin-top: 5px;
}

.map-mode {
  position: absolute;
  display: flex;
  z-index: 1;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  pointer-events: none;
  align-items: center;
}
.map-mode-label {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 100px;
  font-size: 16px;
  border-radius: 0 0 10px 10px;
}
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(222, 222, 222, 0.6);
}

.ant-checkbox-group >>> .ant-checkbox-group-item {
  padding: 0.5em;
  display: block;
  text-align: left;
}
</style>
