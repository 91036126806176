<template>
  <div id="app">
    <div v-if="preview">
      <Preview ref="preview" @mapLoaded="mapLoaded" />
    </div>
    <div v-if="!preview && authorized">
      <Map v-if="!shapeEditing" :survey-info="surveyInfo" @mapsLoaded="mapsLoaded" @stateChange="stateChange" />
      <Wizard v-if="!shapeEditing" ref="wizard" :gmap="mapObj" :is-dirty="isDirty" @next="onNext" />
      <ShapeEditor
        v-if="shapeEditing"
        :survey-info="surveyInfo"
        :bbox="ortoBbox"
        :ortho-name="orthoName"
        @back="onBack"
      />
      <div class="btn-feedback" @click="openFeedback">Help & Feedback</div>
    </div>
  </div>
</template>

<script>
import Map from './components/Map.vue';
import Wizard from './components/Wizard.vue';
import ShapeEditor from './components/ShapeEditor.vue';
import Preview from './components/Preview.vue';
import apiProvider from './providers/api';
import productManagerProvider from './providers/product-manager-api';
import { message } from 'ant-design-vue';
import * as Sentry from '@sentry/vue';

export default {
  name: 'App',
  components: {
    Map,
    Wizard,
    ShapeEditor,
    Preview
  },
  data() {
    return {
      mapObj: null,
      ortoBbox: [],
      orthoName: '',
      shapeEditing: false,
      isDirty: false,
      userInfo: null,
      preview: false,
      authorized: false,
      surveyInfo: {},
      refreshSessionInterval: 600000 // millisecond, 10 min
    };
  },
  async mounted() {
    this.preview = window.location.pathname.indexOf('preview') >= 0;
    await this.authorize();

    setInterval(() => {
      productManagerProvider.refreshSession();
    }, this.refreshSessionInterval);
  },
  methods: {
    openFeedback() {
      const userSnapCommentTemplate =
        'Title: \n\n' +
        '🔴 Actual result (what you see):\n\n' +
        '🟢 Desired result (what you expect to see):\n\n' +
        '🔃 Steps to reproduce (what you did to get the actual result):\n';

      const userSnap = window.userSnap;
      if (userSnap) {
        userSnap.on('open', (event) => {
          event.api.setValue('visitor', this.userInfo ? this.userInfo.Email : null);
          event.api.setValue('comment', userSnapCommentTemplate);
          event.api.setValue('custom', this.userInfo);
        });
        userSnap.open();
      }
    },

    mapLoaded(map) {
      this.mapObj = map;

      apiProvider.getBounds(this.orthoName).then((data) => {
        this.$refs.preview.showOrtho([data.minx, data.miny, data.maxx, data.maxy], this.orthoName);
      });
    },

    mapsLoaded(map) {
      this.mapObj = map;
      this.wizardObj = this.$refs.wizard;

      apiProvider.getBounds(this.orthoName).then((data) => {
        this.ortoBbox = [data.minx, data.miny, data.maxx, data.maxy];
        this.wizardObj.openBookmark(data, this.orthoName);
      });
    },

    stateChange(value) {
      this.isDirty = value;
    },

    onNext() {
      this.shapeEditing = true;
    },

    onBack() {
      this.shapeEditing = false;
    },

    async authorize() {
      try {
        const result = await productManagerProvider.authorize();
        this.userInfo = result.UserInfo;
        if (
          !this.preview &&
          (!this.userInfo.Roles || !this.userInfo.Roles.find((role) => role === 'admin' || role === 'aligner'))
        ) {
          message.error('Sorry, you do not have necessary previlege to access this page.');
        } else {
          this.authorized = true;
          this.orthoName = this.getClusterId();
          this.surveyInfo = await apiProvider.getSurveyInfo(this.orthoName);
          Sentry.setUser({
            email: this.userInfo.Email,
            username: this.userInfo.FirstName + ' ' + this.userInfo.LastName
          });
        }
      } catch (error) {
        this.authRedirect();
      }
    },

    authRedirect() {
      window.location.href = process.env.VUE_APP_AUTH_SERVER + '?redir=' + encodeURIComponent(window.location.href);
      return;
    },

    getClusterId() {
      if (this.preview) {
        const parts = window.location.pathname.split('/');
        const name = parts[parts.length - 1];
        return name;
      }
      const id = window.location.pathname.substr(1);
      return id;
    }
  }
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.btn-feedback {
  color: white;
  background: #309257;
  position: fixed;
  right: 0;
  font-size: 13px;
  bottom: 50%;
  padding: 0 10px;
  z-index: 2147483647;
  border: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
  transform-origin: 100% 100% !important;
  transform: rotateZ(-90deg) translateX(50%) translateY(0px) !important;
}
</style>
