var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Split',{staticClass:"split-container"},[_c('SplitArea',{staticStyle:{"position":"relative"},attrs:{"size":50}},[_c('div',{attrs:{"id":"map1"}},[_c('mapbox',{attrs:{"access-token":"pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ","map-options":{
          container: 'map1',
          style: 'mapbox://styles/mapbox/satellite-v9',
          center: [-96, 37.8],
          zoom: 3,
          tileSize: 256,
          preserveDrawingBuffer: true,
          dragRotate: false,
          touchZoomRotate: false
        }},on:{"map-load":_vm.loaded1}})],1)]),_c('SplitArea',{staticStyle:{"position":"relative"},attrs:{"size":50}},[_c('div',{attrs:{"id":"map2"}},[_c('mapbox',{attrs:{"access-token":"pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ","map-options":{
          container: 'map2',
          style: 'mapbox://styles/mapbox/satellite-v9',
          center: [-96, 37.8],
          zoom: 3,
          tileSize: 256,
          preserveDrawingBuffer: true,
          dragRotate: false,
          touchZoomRotate: false
        }},on:{"map-load":_vm.loaded2}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }