<template>
  <Split class="split-container">
    <SplitArea :size="50" style="position: relative">
      <div id="map1">
        <mapbox
          access-token="pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ"
          :map-options="{
            container: 'map1',
            style: 'mapbox://styles/mapbox/satellite-v9',
            center: [-96, 37.8],
            zoom: 3,
            tileSize: 256,
            preserveDrawingBuffer: true,
            dragRotate: false,
            touchZoomRotate: false
          }"
          @map-load="loaded1"
        />
      </div>
    </SplitArea>
    <SplitArea :size="50" style="position: relative">
      <div id="map2">
        <mapbox
          access-token="pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ"
          :map-options="{
            container: 'map2',
            style: 'mapbox://styles/mapbox/satellite-v9',
            center: [-96, 37.8],
            zoom: 3,
            tileSize: 256,
            preserveDrawingBuffer: true,
            dragRotate: false,
            touchZoomRotate: false
          }"
          @map-load="loaded2"
        />
      </div>
    </SplitArea>
  </Split>
</template>

<script>
import axios from 'axios';
import { Split, SplitArea } from 'vue-split-panel';
import Mapbox from 'mapbox-gl-vue';
import mapboxgl from 'mapbox-gl';

export default {
  components: { Split, SplitArea, Mapbox },
  props: {
    surveyInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      map1: null,
      map2: null,
      bounds: null,
      fns: [],
      markers1: [],
      markers2: []
    };
  },
  methods: {
    loaded1(map) {
      this.map1 = map;
      if (this.map2 != null) {
        this.$emit('mapsLoaded', this);
        this.syncMaps();
      }
    },
    async loaded2(map) {
      this.map2 = map;
      if (this.map1 != null) {
        this.$emit('mapsLoaded', this);
        this.$emit('stateChange', false);
        this.syncMaps();
      }
      if (!this.surveyInfo.gcps) {
        this.map2.on('click', (ev) => {
          var r = Math.round(Math.random() * 255);
          var g = Math.round(Math.random() * 255);
          var b = Math.round(Math.random() * 255);
          this.$emit('stateChange', true);
          var m1 = new mapboxgl.Marker({ color: `rgb(${r}, ${g}, ${b})` })
            .setLngLat(ev.lngLat)
            .setDraggable(true)
            .addTo(this.map1);
          var m2 = new mapboxgl.Marker({ color: `rgb(${r}, ${g}, ${b})` })
            .setLngLat(ev.lngLat)
            .setDraggable(true)
            .addTo(this.map2);
          this.markers2.push(m2);
          this.markers1.push(m1);
          m1.getElement().setAttribute('title', '' + this.markers1.length);
          m2.getElement().setAttribute('title', '' + this.markers2.length);
          m2.getElement().addEventListener('contextmenu', (ev) => {
            var idx = ev.currentTarget.getAttribute('title') - 1;
            this.markers1[idx].remove();
            this.markers1.splice(idx, 1);
            this.markers2[idx].remove();
            this.markers2.splice(idx, 1);
            for (var i = idx; i < this.markers1.length; i++) {
              this.markers1[i].getElement().setAttribute('title', '' + (i + 1));
              this.markers2[i].getElement().setAttribute('title', '' + (i + 1));
            }
          });
        });
      }
    },
    align(name, width, height) {
      var gcps = [];
      for (var i = 0; i < this.markers1.length; i++) {
        var m1 = this.markers1[i];
        var m2 = this.markers2[i];
        var pixpos = m2.getLngLat();
        var gcp = m1.getLngLat();
        gcps.push(
          [
            '-gcp',
            ((this.bounds[0][0] - pixpos.lng) / (this.bounds[0][0] - this.bounds[2][0])) * width,
            ((this.bounds[0][1] - pixpos.lat) / (this.bounds[0][1] - this.bounds[2][1])) * height,
            gcp.lng,
            gcp.lat
          ].join(' ')
        );
      }
      axios
        .get(process.env.VUE_APP_APPSERVER + '/align?name=' + name + '&gcps=' + gcps.join(' '), {
          withCredentials: true
        })
        .then(() => {
          /*var info = alignResult.data;
          if (info.message != null) {
            alert(info.message);
            return;
          }
          var bounds = [
            [info.minx, info.maxy],
            [info.maxx, info.maxy],
            [info.maxx, info.miny],
            [info.minx, info.miny]
          ];
          this.showOrtho(bounds, name, this.map1);*/
        });
      return true;
    },
    async showOrtho(bounds, name, map) {
      this.bounds = [
        [bounds[0], bounds[3]],
        [bounds[2], bounds[3]],
        [bounds[2], bounds[1]],
        [bounds[0], bounds[1]]
      ];
      const str = bounds.join('-');
      const hash = await this.getDigest(str);
      map.addSource('ortho', {
        type: 'raster',
        tiles: [
          `https://storage.googleapis.com/drone-tiles-${process.env.VUE_APP_ENV}/${name}/{z}/{x}/{y}.png?v=${hash}`
        ],
        bounds: bounds,
        tileSize: 256
      });
      map.addLayer({
        id: 'ortho-layer',
        type: 'raster',
        source: 'ortho',
        minZoom: 10,
        maxZoom: 22
      });

      if (this.surveyInfo.gcps && this.surveyInfo.gcps.length > 0) {
        this.surveyInfo.gcps.map((gcp) => {
          const r = Math.round(Math.random() * 255);
          const g = Math.round(Math.random() * 255);
          const b = Math.round(Math.random() * 255);
          this.$emit('stateChange', true);
          const m2 = new mapboxgl.Marker({ color: `rgb(${r}, ${g}, ${b})` })
            .setLngLat([gcp.long, gcp.lat])
            .setDraggable(true)
            .addTo(this.map2);
          this.markers2.push(m2);

          const m1 = new mapboxgl.Marker({ color: `rgb(${r}, ${g}, ${b})` })
            .setLngLat([gcp.long, gcp.lat])
            .setDraggable(false)
            .addTo(this.map1);
          this.markers1.push(m1);

          m2.getElement().setAttribute('title', '' + this.markers2.length);
          m1.getElement().setAttribute('title', '' + this.markers1.length);
          m2.getElement().addEventListener('contextmenu', (ev) => {
            const idx = ev.currentTarget.getAttribute('title') - 1;
            this.markers1[idx].remove();
            this.markers1.splice(idx, 1);
            this.markers2[idx].remove();
            this.markers2.splice(idx, 1);
            for (var i = idx; i < this.markers1.length; i++) {
              this.markers1[i].getElement().setAttribute('title', '' + (i + 1));
              this.markers2[i].getElement().setAttribute('title', '' + (i + 1));
            }
          });
        });
      }
    },
    async getDigest(message) {
      const msgBuffer = new TextEncoder('utf-8').encode(message);
      // works only for https
      if (crypto.subtle) {
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('');
        return hashHex;
      }
      return new Date().getTime();
    },
    async showParcels(bbox, map, parcels) {
      //const { data: serveyInfo } = await this.getSurveyInfo(surveyId);
      //const parcelData = await parcelProvider.getParcelWithShape(serveyInfo.parcelIds);

      parcels.map((parcel) => {
        const parcelShapeCollection = { ...parcel.Shape, properties: { id: parcel.id } };
        map.addSource('parcels-src-' + parcel.id, {
          type: 'geojson',
          data: parcelShapeCollection
        });
        map.addLayer({
          id: 'parcels-layer-' + parcel.id,
          type: 'line',
          source: 'parcels-src-' + parcel.id,
          paint: {}
        });
      });
      /*const parcelShapeCollection = { ...parcelData.shape, properties: { id: parcelData.parcelId } };
      map.addSource('parcels-src', {
        type: 'geojson',
        data: parcelShapeCollection
      });
      map.addLayer({
        id: 'parcels-layer',
        type: 'line',
        source: 'parcels-src',
        paint: {}
      });*/
    },
    syncMaps() {
      if (this.map1 == null || this.map2 == null) {
        return;
      }
      this.fns = [];
      var maps = [this.map1, this.map2];
      maps.forEach((map, index) => {
        this.fns[index] = this.sync.bind(
          null,
          map,
          maps.filter((o, i) => {
            return i !== index;
          })
        );
      });
      maps.forEach((map, index) => {
        map.on('move', this.fns[index]);
      });
    },
    sync(master, clones) {
      var maps = [this.map1, this.map2];
      maps.forEach((map, index) => {
        map.off('move', this.fns[index]);
      });
      var center = master.getCenter();
      var zoom = master.getZoom();
      var bearing = master.getBearing();
      var pitch = master.getPitch();
      clones.forEach((clone) => {
        clone.jumpTo({
          center: center,
          zoom: zoom,
          bearing: bearing,
          pitch: pitch
        });
      });
      maps.forEach((map, index) => {
        map.on('move', this.fns[index]);
      });
    },
    setCenter(point) {
      this.map1.setCenter(point);
      this.map2.setCenter(point);
    },
    setZoom(zoom) {
      this.map1.setZoom(zoom);
      this.map2.setZoom(zoom);
    },
    fitBounds(bounds) {
      const options = { animate: false };
      this.map1.fitBounds(bounds, options);
      this.map2.fitBounds(bounds, options);
    }
  }
};
</script>

<style scoped>
.split-container {
  height: calc(100vh - 0px);
  width: 100%;
}
#map1,
#map2 {
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>
