<template>
  <div id="panel">
    <div v-if="!processing">
      <!--a-button v-if="!isDirty" type="primary" @click="align()">Align</a-button-->
      <a-button :disabled="!isDirty" class="btn-indent" @click="align()">Save</a-button>
      <a-button class="btn-indent" @click="reset()">Reset</a-button>
    </div>
    <div v-if="processing">
      <div>{{ state }}</div>
      <a-progress :percent="progress" />
    </div>
    <div v-if="!processing" style="float: right; margin-top: 20px">
      <a-button type="primary" @click="showShapeEditor()">
        Next
        <a-icon type="arrow-right" />
      </a-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { message } from 'ant-design-vue';
import productManagerProvider from '../providers/product-manager-api';
export default {
  props: {
    gmap: {
      type: Object,
      default: null
    },
    refresh: {
      type: Function,
      default: () => {}
    },
    isDirty: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bookmarkName: '',
      bounds: {},
      processing: false,
      firstTime: true,
      progress: 0,
      state: ''
    };
  },
  mounted() {},
  methods: {
    align() {
      this.processing = this.gmap.align(this.bookmarkName, this.bounds.width, this.bounds.height);
      setTimeout(() => {
        this.getProgress();
      }, 2000);
    },
    getProgress(isReset) {
      axios
        .get(process.env.VUE_APP_APPSERVER + '/getProgress?id=' + this.bookmarkName, { withCredentials: true })
        .then((res) => {
          if (res.data.state != null) {
            this.state = res.data.state;
            if (this.state == 'Error') {
              message.error(res.data.message, 0);
              return;
            }
            if (this.state == 'Completed') {
              if (!isReset) {
                this.showShapeEditor();
              } else {
                window.location.reload();
              }
            } else {
              this.processing = true;
              if (res.data.progress > 100) {
                res.data.progress = 100;
              }
              switch (this.state) {
                case 'Downloading Ortho':
                  this.progress = res.data.progress * 0.1;
                  break;
                case 'Warping':
                  this.progress = 10 + res.data.progress * 0.2;
                  break;
                case 'Uploading Ortho':
                  this.progress = 30 + res.data.progress * 0.1;
                  break;
                case 'Copying':
                  this.progress = 40 + res.data.progress * 0.05;
                  break;
                case 'Tiling':
                  if (isReset) {
                    this.progress = 45 + res.data.progress * 0.4;
                  } else {
                    this.progress = 45 + res.data.progress * 0.4;
                  }
                  break;
                case 'Copying Tiles':
                  this.progress = 84 + res.data.progress * 0.15;
                  break;
              }
              this.progress = Math.round(this.progress);
            }
          }
          setTimeout(() => {
            this.getProgress(isReset);
          }, 5000);
        });
    },
    reset() {
      this.processing = true;
      axios({
        method: 'GET',
        url: process.env.VUE_APP_APPSERVER + '/reset?name=' + this.bookmarkName
      }).then((resetResult) => {
        if (resetResult.data) {
          setTimeout(() => {
            this.getProgress(true);
          }, 3000);
        }
      });
    },
    openBookmark(bounds, name) {
      this.gmap.fitBounds([
        [bounds.minx, bounds.miny],
        [bounds.maxx + (bounds.maxx - bounds.minx), bounds.maxy]
      ]);
      this.bounds = bounds;
      this.bookmarkName = name;
      if (bounds.minx != null) {
        const bbox = [bounds.minx, bounds.miny, bounds.maxx, bounds.maxy];
        this.drawOrtho(bbox, name);
        this.drawParcels(bbox, name);
      }
    },
    drawOrtho(bounds, name) {
      this.gmap.showOrtho(bounds, name, this.gmap.map2);
    },
    async drawParcels(bounds, name) {
      const parcels = await productManagerProvider.getClusterParcels(name);
      if (parcels.some((p) => p.SurveyID != null && p.SurveyID.length > 0)) {
        this.showShapeEditor();
      } else {
        this.gmap.showParcels(bounds, this.gmap.map2, parcels);
      }
    },
    showShapeEditor() {
      this.$emit('next');
    }
  }
};
</script>
<style scoped>
#panel {
  z-index: 2;
  position: absolute;
  left: 10px;
  top: 15px;
  background: rgba(221, 221, 221, 0.7);
  padding: 20px;
  width: 250px;
  border-radius: 5px;
}
.btn-indent {
  margin-left: 20px;
}
</style>
