<template>
  <div id="map">
    <mapbox
      access-token="pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ"
      :map-options="{
        container: 'map',
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: [-96, 37.8],
        zoom: 3,
        tileSize: 256,
        preserveDrawingBuffer: true,
        dragRotate: false,
        touchZoomRotate: false
      }"
      @map-load="loaded"
    />
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
import productManagerProvider from '../providers/product-manager-api';

export default {
  components: { Mapbox },
  data() {
    return {
      map: null,
      bounds: null,
      fns: []
    };
  },
  methods: {
    loaded(map) {
      this.map = map;
      this.$emit('mapLoaded', this);
    },
    async showOrtho(bounds, name) {
      this.bounds = [
        [bounds[0], bounds[3]],
        [bounds[2], bounds[3]],
        [bounds[2], bounds[1]],
        [bounds[0], bounds[1]]
      ];
      this.fitBounds(bounds);
      const str = bounds.join('-');
      const hash = await this.getDigest(str);
      this.map.addSource('ortho', {
        type: 'raster',
        tiles: [
          `https://storage.googleapis.com/drone-tiles-${process.env.VUE_APP_ENV}/${name}/{z}/{x}/{y}.png?v=${hash}`
        ],
        bounds: bounds,
        tileSize: 256
      });
      this.map.addLayer({
        id: 'ortho-layer',
        type: 'raster',
        source: 'ortho',
        minZoom: 10,
        maxZoom: 22
      });
      const parcels = await productManagerProvider.getClusterParcels(name);
      parcels.map((parcel) => {
        const parcelShapeCollection = { ...parcel.Shape, properties: { id: parcel.id } };
        this.map.addSource('parcels-src-' + parcel.id, {
          type: 'geojson',
          data: parcelShapeCollection
        });
        this.map.addLayer({
          id: 'parcels-layer-' + parcel.id,
          type: 'line',
          source: 'parcels-src-' + parcel.id,
          paint: {}
        });
      });
    },
    async getDigest(message) {
      const msgBuffer = new TextEncoder('utf-8').encode(message);
      const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('');
      return hashHex;
    },
    async showParcels(bbox, map, parcels) {
      parcels.map((parcel) => {
        const parcelShapeCollection = { ...parcel.Shape, properties: { id: parcel.id } };
        map.addSource('parcels-src-' + parcel.id, {
          type: 'geojson',
          data: parcelShapeCollection
        });
        map.addLayer({
          id: 'parcels-layer-' + parcel.id,
          type: 'line',
          source: 'parcels-src-' + parcel.id,
          paint: {}
        });
      });
    },
    setCenter(point) {
      this.map.setCenter(point);
    },
    setZoom(zoom) {
      this.map.setZoom(zoom);
    },
    fitBounds(bounds) {
      const options = { animate: false };
      this.map.fitBounds(bounds, options);
    }
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>
