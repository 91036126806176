import axios from 'axios';
import { handleError } from './common';

const baseURL = `${process.env.VUE_APP_APPSERVER}`;

const apiClient = axios.create({
  baseURL,
  withCredentials: true
});

export default {
  async getSurveyInfo(id) {
    const { data: surveyInfo } = await apiClient.get('/survey_info', {
      params: { id },
      withCredentials: true
    });

    return surveyInfo;
  },

  async getBounds(id) {
    const { data: bounds } = await apiClient.get('/getBounds', {
      params: { name: id },
      withCredentials: true
    });

    return bounds;
  },

  async updateClusterMeta(clusterId, updateInput) {
    try {
      const { data } = await apiClient.patch(
        `/cluster-meta/${clusterId}`,
        {
          parcelIds: updateInput.parcelIds
        },
        {
          withCredentials: true
        }
      );

      return data;
    } catch (error) {
      handleError("can't update cluster meta data", error);
    }
  }
};
