<template>
  <a-modal
    title="Parcel properties"
    :closable="false"
    :mask-closable="false"
    :destroy-on-close="true"
    :visible="true"
    dialog-class="dialog"
    :ok-button-props="{ props: { disabled: parcelName.length === 0 } }"
    :cancel-button-props="{ props: { disabled: true } }"
    @cancel="done()"
    @ok="done()"
  >
    <div class="block">
      <div class="title">Organization</div>
      <div class="value">{{ organization.Name }}</div>
    </div>
    <div class="block">
      <div class="title">Unit</div>
      <div class="value">{{ unit.Name }}</div>
    </div>
    <div class="block">
      <div class="title">Farm</div>
      <div class="value">{{ farm.Name }}</div>
    </div>
    <div class="block">
      <div class="title">Parcel name</div>
      <div class="value">
        <a-input v-model="parcelName" placeholder="Type parcel name" />
      </div>
    </div>
    <div class="block">
      <div class="title">Planting date</div>
      <div class="value">
        <a-date-picker v-model="plantingDate" :allow-clear="false" />
      </div>
    </div>
  </a-modal>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    featureId: {
      type: String,
      default: ''
    },
    surveyInfo: {
      type: Object,
      default: () => ({})
    },
    organization: {
      type: Object,
      default: () => ({})
    },
    farm: {
      type: Object,
      default: () => ({})
    },
    unit: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      parcelName: '',
      plantingDate: moment.utc(this.surveyInfo.surveyDate).subtract(90, 'days')
    };
  },
  computed: {},
  methods: {
    done() {
      const data = {
        parcelName: this.parcelName,
        plantingDate: this.plantingDate.toISOString()
      };
      this.$emit('onEdited', this.featureId, data);
    }
  }
};
</script>

<style scoped>
.block {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
}

.block:first-child {
  margin-top: 0;
}

.title {
  color: #1f1f1f;
}

.value {
}
</style>
