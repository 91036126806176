var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"map"}},[_c('mapbox',{attrs:{"access-token":"pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ","map-options":{
      container: 'map',
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [-96, 37.8],
      zoom: 3,
      tileSize: 256,
      preserveDrawingBuffer: true,
      dragRotate: false,
      touchZoomRotate: false
    }},on:{"map-load":_vm.loaded}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }