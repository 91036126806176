import { lineString } from '@turf/helpers';
import lineToPolygon from '@turf/line-to-polygon';
import cleanCoords from '@turf/clean-coords';
import buffer from '@turf/buffer';

const paintMode = function () {};

paintMode.onDrawFinished = function () {};

paintMode.onSetup = function (options) {
  return {
    drawing: false,
    lng: null,
    lat: null,
    coordinates: [],
    lineIds: [],
    lineColor: options.color,
    lineBuffer: options.lineBuffer
  };
};

paintMode.onStop = function (state) {
  if (state.lineIds && state.lineIds.length) {
    state.lineIds.forEach((id) => {
      this.deleteFeature(id);
    });
  }
};

paintMode.onMouseMove = function (state, e) {
  if (!e.originalEvent.shiftKey) {
    if (state.lng && state.lat && state.coordinates.length > 1) {
      state.lineIds.forEach((id) => {
        this.deleteFeature(id);
      });
      let drawPolygon = null;
      const line = cleanCoords(lineString(state.coordinates));
      if (line && line.geometry.coordinates.length >= 4) {
        if (state.lineBuffer) {
          drawPolygon = buffer(line, state.lineBuffer, { units: 'meters' });
        } else {
          drawPolygon = lineToPolygon(line);
        }
      }
      this.onDrawFinished(drawPolygon);
    }
    state.lng = null;
    state.lat = null;
    state.coordinates = [];
    state.lineIds = [];
    return;
  }
  if (state.lng && state.lat) {
    const line = this.newFeature({
      type: 'Feature',
      properties: {
        color: state.lineColor,
        lineBuffer: state.lineBuffer
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [state.lng, state.lat],
          [e.lngLat.lng, e.lngLat.lat]
        ]
      }
    });
    state.lineIds.push(line.id);
    this.addFeature(line);
  }
  state.lng = e.lngLat.lng;
  state.lat = e.lngLat.lat;
  state.coordinates.push([e.lngLat.lng, e.lngLat.lat]);
};

paintMode.toDisplayFeatures = function (_state, geojson, display) {
  display(geojson);
};

export default paintMode;
